import { Koporye } from "./../../pages/Project/Koporye/Koporye";
import { AppKolpino } from "./../../pages/Project/AppKolpino/AppKolpino";
import { BrodilkaKolpino } from "./../../pages/Project/BrodilkaKolpino/BrodilkaKolpino";
import { BackToTheRootsNFT } from "pages/Nft/BackToTheRootsNFT/BackToTheRootsNFT";
import { DirtyDrops } from "pages/Project/DirtyDrops/DirtyDrops";
import { Halloween } from "pages/Nft/Halloween/Halloween";
import { Interior } from "pages/Nft/Interior/Interior";
import { MetaFemHistory } from "pages/Nft/MetaFemHistory/MetaFemHistory";
import { NewCityArtist } from "pages/Nft/NewCityArtist/NewCityArtist";
import { NewEast } from "pages/Nft/NewEast/NewEast";
import { NewNames } from "pages/Nft/NewNames/NewNames";
import { BackToTheRoots } from "pages/Project/BackToTheRoots/BackToTheRoots";
import { BrodilkaKurortnyy } from "pages/Project/BrodilkaKurortnyy/BrodilkaKurortnyy";
import { BrodilkaMoscow } from "pages/Project/BrodilkaMoscow/BrodilkaMoscow";
import { BrodilkaViborg } from "pages/Project/BrodilkaViborg/BrodilkaViborg";
import { GeniusLoci } from "pages/Project/GeniusLoci/GeniusLoci";
import { GuideKur } from "pages/Project/GuideKur/GuideKur";
import { Ladoga } from "pages/Project/Ladoga/Ladoga";
import { MyMoscow } from "pages/Project/MyMoscow/MyMoscow";
import { MyViborg } from "pages/Project/MyViborg/MyViborg";
import { StreetArt } from "pages/Project/StreetArt/StreetArt";
import { Kdc } from "pages/Project/Kdc/Kdc";
import { DiscoveryCity } from "pages/Project/DiscoveryCity/DiscoveryCity";
import { Hydrocosmos } from "../../pages/Project/Hydrocosmos/Hydrocosmos";
import { Arka } from "../../pages/Project/Arka/Arka";
import { Grandrims } from "../../pages/Project/Grandrims/Grandrims";
import { Hilkov } from "../../pages/Project/Hilkov/Hilkov";
import { Setka } from "pages/Project/Setka/Setka";
import { Dobro } from "pages/Project/Dobro/Dobro";
import { Nca } from "pages/Project/Nca/Nca";
import { Vsevolozhsk } from "pages/Project/Vsevolozhsk/Vsevolozhsk";
import { Khraniteli } from "pages/Project/Khraniteli/Khraniteli";
import { Giri } from "../../pages/Project/Giri/Giri";
import { MemoVsevolozhsk } from "pages/Project/MemoVsevolozhsk/MemoVsevolozhsk";
import { GorodaPoyezda } from "../../pages/Project/GorodaPoyezda/GorodaPoyezda";
import { Hraniteli3d } from "pages/Project/Hraniteli3d/Hraniteli3d";
import { Bolshaya3d } from 'pages/Project/Bolshaya3d/Bolshaya3d';
import {BTR} from "../../pages/Project/BTR/BTR";
import {GazpromNCA} from "../../pages/Project/GazpromNCA/GazpromNCA";
import { AAG } from 'pages/Project/AAG/AAG';
import { MemoGorodAnimate } from 'pages/Project/MemoGorodAnimate/MemoGorodAnimate';
import { MemoVsevAnimate } from 'pages/Project/MemoVsevAnimate/MemoVsevAnimate';
import { ComicHero } from 'pages/Project/ComicHero/ComicHero';
import {HilkovApp} from "../../pages/Project/HilkovApp/HilkovApp";
import WebDevelopment from "../../pages/brief/Questions/Questions";
import {Vita} from "../../pages/Project/Vita/Vita";
import { LetniySad } from "pages/Project/LetnySad/LetnySad";
import { NevskyRunner } from "pages/Project/NevskyRunner/NevskyRunner";
import { MemoMariinskiy } from "pages/Project/MemoMariinskiy/MemoMariinskiy";
import { VerniyPut } from "pages/Project/VerniyPut/VerniyPut";
import {SerebryanoeOzherele} from "../../pages/Project/SerebryanoeOzherele/SerebryanoeOzherele";
import {Sujda} from "../../pages/Project/Sujda/Sujda";

type Routes = {
  path: string;
  locales: string;
  Component: () => JSX.Element;
};

export const nftRoutes: Routes[] = [
  {
    path: "/dirtyDrops",
    locales: "dirtydrops",
    Component: DirtyDrops,
  },
  {
    path: "/halloween",
    locales: "halloween",
    Component: Halloween,
  },
  {
    path: "/backToTheRoots",
    locales: "btrNft",
    Component: BackToTheRootsNFT,
  },
  {
    path: "/metaFemHistory",
    locales: "metafemhistory",
    Component: MetaFemHistory,
  },
  {
    path: "/newNamesInFashion",
    locales: "newnames",
    Component: NewNames,
  },
  {
    path: "/newCityDriver",
    locales: "newcity",
    Component: NewCityArtist,
  },
  {
    path: "/newEastTechAwards",
    locales: "neweast",
    Component: NewEast,
  },
  {
    path: "/nftInterior",
    locales: "interior",
    Component: Interior,
  },
];

export const projectRoutes: Routes[] = [
  {
    path: "verniyPut",
    locales: "verniyPut",
    Component: VerniyPut,
  },
  {
    path: "sujda",
    locales: "verniyPut",
    Component: Sujda,
  },
  {
    path: "serebryanoe-ozherele",
    locales: "serebryanoe-ozherele",
    Component: SerebryanoeOzherele,
  },
  {
    path: "memoMariinskiy",
    locales: "memoMariinskiy",
    Component: MemoMariinskiy,
  },
  {
    path: "giri",
    locales: "giri",
    Component: Giri,
  },
  {
    path: "GazpromNCA",
    locales: "GazpromNCA",
    Component: GazpromNCA,
  },
  {
    path: "dirtyDrops",
    locales: "dirtyDrops",
    Component: DirtyDrops,
  },
  {
    path: "BTR",
    locales: "BTR",
    Component: BTR,
  },
  {
    path: "mymoscow",
    locales: "mymoscow",
    Component: MyMoscow,
  },
  {
    path: "ladogaGuide",
    locales: "ladoga",
    Component: Ladoga,
  },
  {
    path: "myViborg",
    locales: "myviborg",
    Component: MyViborg,
  },
  {
    path: "streetArtMemo",
    locales: "streetart",
    Component: StreetArt,
  },
  {
    path: "geniusLociMemo",
    locales: "geniusloci",
    Component: GeniusLoci,
  },
  {
    path: "backToTheRootsLanding",
    locales: "backtotheroots",
    Component: BackToTheRoots,
  },
  {
    path: "questViborg",
    locales: "brodilkaviborg",
    Component: BrodilkaViborg,
  },
  {
    path: "questMoscow",
    locales: "brodilkamoscow",
    Component: BrodilkaMoscow,
  },
  {
    path: "questKurortnyy",
    locales: "brodilkakurortnyy",
    Component: BrodilkaKurortnyy,
  },
  {
    path: "kurortnyyGuide",
    locales: "guidekur",
    Component: GuideKur,
  },
  {
    path: "questKolpino",
    locales: "brodilkakolpino",
    Component: BrodilkaKolpino,
  },
  {
    path: "appKolpino",
    locales: "appkolpino",
    Component: AppKolpino,
  },
  {
    path: "kdc",
    locales: "kdc",
    Component: Kdc,
  },
  {
    path: "koporye",
    locales: "koporye",
    Component: Koporye,
  },
  {
    path: "discoveryCity",
    locales: "discoverycity",
    Component: DiscoveryCity,
  },
  {
    path: "hydrocosmos",
    locales: "hydrocosmos",
    Component: Hydrocosmos,
  },
  {
    path: "setka",
    locales: "setka",
    Component: Setka,
  },
  // {
  //   path: "bags",
  //   locales: "bags",
  //   Component: Bags,
  // },
  {
    path: "dobroGooseGoose",
    locales: "dobroGooseGoose",
    Component: Dobro,
  },
  {
    path: "nca",
    locales: "nca",
    Component: Nca,
  },
  {
    path: "arka",
    locales: "arka",
    Component: Arka,
  },
  {
    path: "grandrims",
    locales: "grandrims",
    Component: Grandrims,
  },
  {
    path: "memo-vsevolozhsk",
    locales: "memovsevolozhsk",
    Component: MemoVsevolozhsk,
  },
  {
    path: "hilkov",
    locales: "hilkov",
    Component: Hilkov,
  },
  {
    path: "vita",
    locales: "vita",
    Component: Vita,
  },
  {
    path: "train-detective",
    locales: "hilkovApp",
    Component: HilkovApp,
  },
  {
    path: "goroda-poyezda",
    locales: "gorodapoyezda",
    Component: GorodaPoyezda,
  },
  {
    path: "vsevolozhsk",
    locales: "vsevolozhsk",
    Component: Vsevolozhsk,
  },
  {
    path: "khraniteli-vremeni",
    locales: "khraniteli",
    Component: Khraniteli,
  },
  {
    path: "khraniteli-vremeni-3d",
    locales: "hraniteli3d",
    Component: Hraniteli3d,
  },
  {
    path: "aag",
    locales: "aag",
    Component: AAG,
  },
  {
    path: "memo-gorod-2d",
    locales: "memoGorodAnimate",
    Component: MemoGorodAnimate,
  },
  {
    path: "memo-vsevolozhsk-2d",
    locales: "memoVsevAnimate",
    Component: MemoVsevAnimate,
  },
  {
    path: "comic-real-hero",
    locales: "comicHero",
    Component: ComicHero,
  },
  {
    path: "vsevolozhsk-3d",
    locales: "bolshaya3d",
    Component: Bolshaya3d,
  },
  {
    path: "letnySad",
    locales: "letnySad",
    Component: LetniySad,
  },
  {
    path: "nevskyRunner",
    locales: "nevskyRunner",
    Component: NevskyRunner,
  },
];

export const briefRouters: Routes[] = [
  {
    path: "web-development",
    locales: "giri",
    Component: WebDevelopment,
  },
];
