import React from "react";
import { Chip } from "@mui/material";
import styled from "styled-components";
import { SELECT_SERVICES } from "shared/constants/brief";
import { breakpoints } from "styles/variables";
import SvgSelector from "shared/components/SvgSelector";

const Chips = ({ selectedServices, setSelectedServices }) => {
  const handleToggle = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter((item) => item !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  return (
    <>
      <ChipsTitle>Выберите услугу</ChipsTitle>
      <ChipsWr>
        {SELECT_SERVICES.map((service) => (
          <StyledChip
            key={service}
            label={
              <ChipLabel>
                {service}
                {selectedServices.includes(service) && <SvgSelector svg="closeChip" />}
              </ChipLabel>
            }
            clickable
            onClick={() => handleToggle(service)}
            selected={selectedServices.includes(service)}
          />
        ))}
      </ChipsWr>
    </>
  );
};

const ChipsWr = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  row-gap: 17px;
  @media ${breakpoints.mobile} {
    margin-bottom: 20px;
    gap: 15px;
    row-gap: 10px;
  }
`;

const ChipsTitle = styled.p`
  align-self: stretch;
  color: #181818;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 44px;
  @media ${breakpoints.mobile} {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
  }
`;

const StyledChip = styled(Chip)`
  && {
    height: 57px;
    padding: 9px 13px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 30px;
    border: 1px solid #1858fb;
    background-color: ${(props) => (props.selected ? "#1858FB" : "transparent")};
    color: ${(props) => (props.selected ? "#fff" : "#181818")};
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

    &:hover {
      background-color: ${(props) => (props.selected ? "#103FB6" : "transparent")};
      border-color: ${(props) => (props.selected ? "#103FB6" : "#1858FB")};
      color: ${(props) => (props.selected ? "#fff" : "#1858FB")};
    }
  }
  @media ${breakpoints.mobile} {
    && {
      height: 35px;
      padding: 7px 10px;
    }
  }
`;

const ChipLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media ${breakpoints.mobile} {
    font-size: 16px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

export default Chips;
