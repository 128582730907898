export const CATEGORIES = {
  site: "сайты",
  mobile_app: "мобильные приложения",
  mobile_game: "мобильные игры",
  ar: "ar-контент",
  board_game: "настольные игры",
  comic: "комиксы",
  graphic: "2d-3d графика",
};

export const cases = [
  {
    imageUrl: "projects/memoMariinskiy/case.jpg",
    name: "Мемо путешествие по мариинскому дворцу",
    category: [CATEGORIES.board_game],
    tags: ["настольная игра"],
    route: "/memoMariinskiy",
  },
  {
    imageUrl: "projects/sujda/case.jpg",
    name: "СУЙДА",
    category: [CATEGORIES.mobile_app],
    tags: ["мобильные приложения"],
    route: "/sujda",
  },
  {
    imageUrl: "projects/SerebryanoeOzherele/case.jpg",
    name: "КОМИКС «СЕРЕБРЯНОЕ ОЖЕРЕЛЬЕ»",
    category: [CATEGORIES.comic],
    tags: ["Комикс"],
    route: "/serebryanoe-ozherele",
  },
  {
    imageUrl: "projects/btr/case.jpg",
    name: "BTR",
    category: [CATEGORIES.graphic],
    tags: ["3d графика"],
    route: "/BTR",
  },
  {
    imageUrl: "projects/dirtyDrops/case.jpg",
    name: "Dirty Drops",
    category: [CATEGORIES.graphic],
    tags: ["3d графика"],
    route: "/dirtyDrops",
  },
  {
    imageUrl: "projects/nevskyRunner/case.jpg",
    name: "Nevsky Runner",
    category: [CATEGORIES.mobile_game],
    tags: ["мобильная игра"],
    route: "/nevskyRunner",
  },
  {
    imageUrl: "projects/gazpromNCA/case.jpg",
    name: "Газпром х NCA",
    category: [CATEGORIES.graphic],
    tags: ["3d графика"],
    route: "/GazpromNCA",
  },
  {
    imageUrl: "projects/kdc/case.jpg",
    name: "кдц московский",
    category: [CATEGORIES.site],
    tags: ["многостраничный сайт"],
    route: "/kdc",
  },
  {
    imageUrl: "projects/giri/case.jpg",
    name: "мобильное приложение «Giri»",
    category: [CATEGORIES.mobile_app],
    tags: ["мобильное приложение"],
    route: "/giri",
  },
  {
    imageUrl: "projects/hydrocosmos/case.jpg",
    name: "научный журнал «гидрокосмос»",
    category: [CATEGORIES.site],
    tags: ["многостраничный сайт"],
    route: "/hydrocosmos",
  },
  {
    imageUrl: "projects/letnySad/case.jpg",
    name: "Мобильное приложение-гид по Летнему саду",
    category: [CATEGORIES.mobile_app],
    tags: ["мобильное приложение"],
    route: "/letnySad",
  },
  {
    imageUrl: "projects/koporye/case.jpg",
    name: "Копорская крепость",
    category: [CATEGORIES.mobile_app, CATEGORIES.mobile_game],
    tags: ["мобильное приложение", "мобильная игра"],
    route: "/koporye",
  },
  {
    imageUrl: "projects/appkolpino/case.jpg",
    name: "мое колпино",
    category: [CATEGORIES.mobile_app, CATEGORIES.ar],
    tags: ["мобильное приложение", "AR"],
    route: "/appKolpino",
  },
  {
    imageUrl: "projects/dobro/case.jpg",
    name: "Добро в goose goose",
    category: [CATEGORIES.site],
    tags: ["лендинг"],
    route: "/dobroGooseGoose",
  },
  {
    imageUrl: "projects/arka/case.jpg",
    name: "мобильное приложение «Arka»",
    category: [CATEGORIES.ar, CATEGORIES.mobile_app],
    tags: ["мобильное приложение", "ar"],
    route: "/arka",
  },
  {
    imageUrl: "projects/setka/case.jpg",
    name: "арт-лавка «Сетка»",
    category: [CATEGORIES.site],
    tags: ["интернет-магазин", "многостраничный сайт"],
    route: "/setka",
  },
  {
    imageUrl: "projects/webar/case.jpg",
    name: "Web ar",
    category: [CATEGORIES.ar, CATEGORIES.site],
    tags: ["web-разработка", "ar"],
    route: "/webar",
  },
  {
    imageUrl: "projects/mymoscow/case.jpg",
    name: "мой московский",
    category: [CATEGORIES.site, CATEGORIES.mobile_app, CATEGORIES.ar],
    tags: ["сайт", "мобильное приложение", "ar"],
    route: "/mymoscow",
  },
  {
    imageUrl: "projects/backtotheroots/case.jpg",
    name: "back to the roots",
    category: [CATEGORIES.site],
    tags: ["лендинг"],
    route: "/backToTheRootsLanding",
  },
  {
    imageUrl: "projects/ladoga/case.jpg",
    name: "аудиогид по&nbsp;новой ладоге",
    category: [CATEGORIES.mobile_app],
    tags: ["мобильное приложение"],
    route: "/ladogaGuide",
  },
  {
    imageUrl: "projects/nca/case.jpg",
    name: "newcitydriver",
    category: [CATEGORIES.site],
    tags: ["лендинг"],
    route: "/nca",
  },
  {
    imageUrl: "projects/publicart/case.jpg",
    name: "publicart",
    category: [CATEGORIES.mobile_app, CATEGORIES.ar],
    tags: ["мобильное приложение", "ar"],
    route: "/publicart",
  },
  {
    imageUrl: "projects/guidekur/case.jpg",
    name: "путеводитель по&nbsp;курортному району",
    category: [CATEGORIES.mobile_app],
    tags: ["мобильное приложение"],
    route: "/kurortnyyGuide",
  },
  {
    imageUrl: "projects/memovsev/case.jpg",
    name: "Мемо всеволожск",
    category: [CATEGORIES.ar, CATEGORIES.board_game],
    tags: ["настольная игра", "ar"],
    route: "/memo-vsevolozhsk",
  },
  {
    imageUrl: "projects/vita/case.jpg",
    name: "«вита будет в порядке»",
    category: [CATEGORIES.comic],
    tags: ["Комикс"],
    route: "/vita",
  },
  {
    imageUrl: "projects/hilkov/case.jpg",
    name: "Комикс по хилкову",
    category: [CATEGORIES.comic],
    tags: ["Комикс"],
    route: "/hilkov",
  },
  {
    imageUrl: "projects/verniyPut/case.jpg",
    name: "«Верный путь»",
    category: [CATEGORIES.comic],
    tags: ["Комикс"],
    route: "/verniyPut",
  },
  {
    imageUrl: "projects/hilkovApp/case.jpg",
    name: "TRAIN DETECTIVE",
    category: [CATEGORIES.mobile_game],
    tags: ["Мобильная игра"],
    route: "/train-detective",
  },
  {
    imageUrl: "projects/goroda-poyezda/case.jpg",
    name: "города и поезда",
    category: [CATEGORIES.board_game],
    tags: ["настольная игра"],
    route: "/goroda-poyezda",
  },
  {
    imageUrl: "projects/vsevolozhsk/case.jpg",
    name: "Большая бродилка по всеволожскому району",
    category: [CATEGORIES.ar, CATEGORIES.board_game],
    tags: ["настольная игра", "ar"],
    route: "/vsevolozhsk",
  },
  {
    imageUrl: "projects/khraniteli/case.jpg",
    name: "хранители времени",
    category: [CATEGORIES.ar, CATEGORIES.board_game],
    tags: ["настольная игра", "ar"],
    route: "/khraniteli-vremeni",
  },
  {
    imageUrl: "projects/discoverycity/case.jpg",
    name: "Мемо город открытий",
    category: [CATEGORIES.board_game, CATEGORIES.ar],
    tags: ["настольная игра", "ar"],
    route: "/discoveryCity",
  },
  {
    imageUrl: "projects/brodilkakolpino/case.jpg",
    name: "Большая Бродилка по&nbsp;колпино",
    category: [CATEGORIES.board_game],
    tags: ["настольная игра"],
    route: "/questKolpino",
  },
  {
    imageUrl: "projects/geniusloci/case.jpg",
    name: "Мемо GENIUS LOCI",
    category: [CATEGORIES.board_game, CATEGORIES.ar],
    tags: ["настольная игра", "AR"],
    route: "/geniusLociMemo",
  },
  {
    imageUrl: "projects/brodilkaviborg/case.jpg",
    name: "Большая Бродилка по&nbsp;выборгу",
    category: [CATEGORIES.board_game, CATEGORIES.ar],
    tags: ["настольная игра", "ar"],
    route: "/questViborg",
  },
  {
    imageUrl: "projects/streetart/case.jpg",
    name: "Мемо street art",
    category: [CATEGORIES.board_game, CATEGORIES.ar],
    tags: ["настольная игра", "ar"],
    route: "/streetArtMemo",
  },
  {
    imageUrl: "projects/brodilkakurortnyy/case.jpg",
    name: "Большая Бродилка по&nbsp;курортному району",
    category: [CATEGORIES.board_game, CATEGORIES.ar],
    tags: ["настольная игра", "ar"],
    route: "/questKurortnyy",
  },
  {
    imageUrl: "projects/brodilkamoscow/case.jpg",
    name: "Большая Бродилка по&nbsp;московскому району",
    category: [CATEGORIES.board_game, CATEGORIES.ar],
    tags: ["настольная игра", "ar"],
    route: "/questMoscow",
  },
  {
    imageUrl: "projects/grandrims/case.jpg",
    name: "GRANDRIMS",
    category: [CATEGORIES.ar, CATEGORIES.mobile_app],
    tags: ["мобильное приложение", "ar"],
    route: "/grandrims",
  },
  {
    imageUrl: "projects/khraniteli3d/case.jpg",
    name: "Хранители времени",
    category: [CATEGORIES.graphic],
    tags: ["3d графика"],
    route: "/khraniteli-vremeni-3d",
  },
  {
    imageUrl: "projects/bolshaya3d/case.jpg",
    name: "3D для большой бродилки",
    category: [CATEGORIES.graphic],
    tags: ["3d графика"],
    route: "/vsevolozhsk-3d",
  },
  {
    imageUrl: "projects/aag/case.jpg",
    name: "Картины для застройщика AAG",
    category: [CATEGORIES.graphic],
    tags: ["2d графика"],
    route: "/aag",
  },
  {
    imageUrl: "projects/memoGorodAnimate/case.jpg",
    name: "мемо «город открытий»",
    category: [CATEGORIES.graphic],
    tags: ["2d графика"],
    route: "/memo-gorod-2d",
  },
  {
    imageUrl: "projects/memoVsevAnimate/case.jpg",
    name: "Мемо «всеволожск»",
    category: [CATEGORIES.graphic],
    tags: ["2d графика"],
    route: "/memo-vsevolozhsk-2d",
  },
  {
    imageUrl: "projects/comicHero/case.jpg",
    name: "Комикс «Настоящий герой»",
    category: [CATEGORIES.graphic],
    tags: ["2d графика"],
    route: "/comic-real-hero",
  },
];
