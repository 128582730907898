import {useTranslation} from "react-i18next";
import {Mainscreen} from "shared/components/Mainscreen/Mainscreen";
import s from "./Sujda.module.scss";
import {useEffect} from "react";
import Aos from "aos";
import {useMediaQuery} from "@mui/material";
import {breakpoints} from "styles/variables";

export const Sujda = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const {t} = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.hydrocosmos}>
      <Mainscreen
        path={`projects/sujda/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                Суйда
              </h2>
              <span className={s.about_left_title_label}>
                Веб-приложение гид по музею-усадьбе «Суйда»
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>веб-приложение</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              По заказу музейного агентства Ленинградской области мы разработали приложение, которое познакомит вас
              с территорией и экспозицией музея-усадьбы «Суйда». Аудиогид сопровождает вас в течение всего путешествия
              по выставочным залам. Образы исторических личностей и экспонаты воссозданы при активном использовании
              сохранившихся исторических данных и архивных источников.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/sujda/image1.jpg")}
            alt="hydrocosmos"
          />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                разработали и реализовали дизайн приложения, который отражает характерный исторический период
              </li>

              <li>
                создали интерактивную карту, в которую добавили фильтр по музейным объектам
              </li>

              <li>
                создали цифровые воплощения выставочных залов, передающих атмосферу быта жизни рода Ганнибалов
              </li>
              <li>на основе архивных данных воссоздали аутентичные образы исторических личностей</li>
              <li>«упаковали» основную информацию об усадьбе в аудиогид, который сопровождает пользователя и помогает
                ответить на вопросы в разделе «Викторина» после прохождения каждого выставочного зала
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>3</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/sujda/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            Мы создали для музея возможность представить свою территорию, экспозицию и главное — историю в цифровом
            формате. Это станет удобным дополнением к посещению музея или позволит сделать взаимодействие с коллекцией
            музея дистанционным.
            <br/>
            <br/>
            Для музея это первый шаг на пути к интеграции своей коллекции в интерактивный веб-формат и превращению
            посетительского опыта в пользовательский. Подобный формат взаимодействия музея со своей аудиторией
            позволит на постоянной основе узнавать об интересах посетителей и предлагать новые форматы интеракции.
          </span>
        </div>
      </div>
    </section>
  );
};
