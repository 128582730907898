import styled from "styled-components";
import { useState } from "react";
import InputLabel from "../shared/InputLabel";
import { breakpoints } from "styles/variables";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const BaseQuestions = () => {
  const [hoveredPhone, setHoveredPhone] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 300,
      offset: 200,
      once: false,
    });
  }, []);

  return (
    <BaseQuestionsWr
      data-aos="fade-right"
      data-aos-anchor-placement="center"
      data-aos-duration="500"
    >
      <InputLabel
        title={"Имя *"}
        placeholder={"Имя"}
        name={"name"}
        animation={false}
      />
      <Connection>
        <div
          onMouseEnter={() => setHoveredPhone(true)}
          onMouseLeave={() => setHoveredPhone(false)}
          style={{ width: "100%" }}
        >
          <InputLabel
            title={"Ваш мобильный *"}
            placeholder={hoveredPhone ? "+7 (900) 000 00 00" : "Введите номер телефона"}
            type={"phone"}
            name={"phone"}
            animation={false}
          />
        </div>

        <InputLabel
          title={"Email *"}
          placeholder={"Email"}
          type={"email"}
          name={"email"}
          animation={false}
        />
      </Connection>
    </BaseQuestionsWr>
  );
};

const BaseQuestionsWr = styled.div`
  margin-bottom: 80px;
  @media ${breakpoints.mobile} {
    margin-bottom: 30px;
  }
`;

const Connection = styled.div`
  width: 100%;
  display: flex;
  gap: 27px;
  @media ${breakpoints.mobile} {
    max-width: 512px;
    flex-direction: column;
    gap: 0;
  }
`;

export default BaseQuestions;
