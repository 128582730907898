import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import svg from "../../../assets/svg/clipBreif.svg";
import { breakpoints } from "styles/variables";
import SvgSelector from "shared/components/SvgSelector";

const FileLoad = ({ title, fieldName }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <AttachFileWrapper>
          <span>{title}</span>
          <label>
            <input
              type={"file"}
              name={fieldName}
              onChange={(event) => {
                field.onChange(event.target.files[0]);
              }}
              hidden
            />
            {field.value ? (
              <DownloadFile id={"idDownloadFile"}>
                <span>Загруженный файл:</span>
                <li>{field.value.name}</li>
              </DownloadFile>
            ) : (
              <AttachFile>
                <SvgSelector svg="paperclip" /> Прикрепить
              </AttachFile>
            )}
          </label>
        </AttachFileWrapper>
      )}
    />
  );
};

const AttachFileWrapper = styled.div`
  margin-bottom: 40px;
  position: relative;
  width: 80%;
  border-radius: 24px;
  justify-self: center;
  align-items: center;
  transition: 1s;
  label {
    cursor: pointer;
    display: block;
    height: auto;
    width: fit-content;
  }
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 24px;
  @media ${breakpoints.mobile} {
    font-size: 15px;
  }

  &:hover {
    &:before {
      transform: rotatez(90deg);
    }
  }
`;

const DownloadFile = styled.ul`
  font-family: "Mulish";
  font-weight: 400;
  margin-top: 8px;
  margin-left: 10px;
  color: #1858fb;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  & li {
    margin: 5px 10px;
    transition: 1s;
  }
  & span {
    font-size: 20px;
    line-height: 26px;
    transition: 1s;
  }
`;

const AttachFile = styled.div`
  color: #1858fb;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  width: fit-content;
  margin-top: 20px;

  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  svg {
    transition: 0.4s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate(70deg);
    }
  }

  @media ${breakpoints.mobile} {
    font-weight: 500;
    font-size: 16px;
    gap: 3px;
  }
`;

export default FileLoad;
