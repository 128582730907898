import * as zod from "zod";

const commonInputSchema = zod.string();
const commonReqInputSchema = zod.string().min(1, { message: "Это поле обязательно к заполнению" });
const fileInputSchema = zod.any();
const checkboxSchema = zod.array(zod.string()).optional();

const phoneSchema = zod.string().regex(/^(\+7|7|8)?[\s-]?(\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}|\d{10,15})$/, {
  message: "Неверный формат номера телефона",
});

const emailSchema = zod.string().email({
  message: "Неверный формат email",
});

export const baseSchema = zod.object({
  name: commonReqInputSchema,
  phone: phoneSchema,
  email: emailSchema,
  companyName: commonReqInputSchema,
  fieldOfActivity: commonReqInputSchema,
  products: commonReqInputSchema,
  clients: commonReqInputSchema,
  competitors: commonReqInputSchema,
  advantages: commonInputSchema,
  tasks: commonReqInputSchema,
  deadlines: commonReqInputSchema,
  offers: commonInputSchema,
});

export const webDevSchema = zod.object({
  site: commonReqInputSchema,
  sections: commonReqInputSchema,
  stuff: commonInputSchema,
  favSites: commonInputSchema,
  hateSites: commonInputSchema,
  technicalSpecifications: fileInputSchema,
  features: checkboxSchema,
  other: commonInputSchema,
});
