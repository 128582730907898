import styled from "styled-components";
import InputLabel from "../shared/InputLabel";
import { useEffect } from "react";
import { breakpoints } from "styles/variables";
import FileLoad from "../shared/FileLoad";
import CheckboxLabels from "../shared/Checkbox";
import Aos from "aos";
import "aos/dist/aos.css";
import { CHECKBOX_LABELS, WED_DEV_BRIEF } from "shared/constants/brief";

const WebDevQuestions = () => {
  useEffect(() => {
    Aos.init({
      duration: 300,
      offset: 200,
      once: false,
    });
  }, []);

  return (
    <PageWr>
      <div
        data-aos="fade-left"
        data-aos-anchor-placement="center"
        data-aos-duration="500"
      >
        <Title>{WED_DEV_BRIEF[0].pageTitle}</Title>
      </div>
      <Visible>
        <TitleSmall>{WED_DEV_BRIEF[0].pageTitleSmall}</TitleSmall>
        {WED_DEV_BRIEF.map((input, inputIndex) => (
          <InputWr key={`${inputIndex}-web-brief-input`}>
            {input.type === "file" ? (
              <div
                data-aos="fade-right"
                data-aos-anchor-placement="center"
                data-aos-duration="500"
              >
                <FileLoad
                  title={input.title}
                  fieldName={input.name}
                />
              </div>
            ) : input.type === "area" ? (
              <InputLabel
                type={input.type}
                title={input.title}
                name={input.name}
              />
            ) : input.type === "checkbox" ? (
              <div
                data-aos="fade-right"
                data-aos-anchor-placement="center"
                data-aos-duration="500"
              >
                <CheckboxLabels
                  labels={CHECKBOX_LABELS}
                  name={input.name}
                  title={input.title}
                />
              </div>
            ) : (
              <InputLabel
                title={input.title}
                placeholder={input.placeholder}
                name={input.name}
              />
            )}
          </InputWr>
        ))}
      </Visible>
    </PageWr>
  );
};

const PageWr = styled.div`
  width: 100%;
`;

const Visible = styled.div`
  /* transform: translateX(${(props) => props.show}px); */
  /* display: ${(props) => (props.show ? "block" : "none")}; */
  min-height: 500px;
`;

export const Title = styled.h2`
  margin-top: 80px;
  margin-bottom: 40px;

  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  font-size: 44px;

  @media ${breakpoints.mobile} {
    font-size: 28px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
`;

const InputWr = styled.div``;

const TitleSmall = styled.div`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 24px;
  margin-bottom: 32px;
  @media ${breakpoints.mobile} {
    font-size: 15px;
  }
`;

export default WebDevQuestions;
