import styled from "styled-components";
import { breakpoints } from "styles/variables";
import { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import Aos from "aos";
import "aos/dist/aos.css";

const InputLabel = ({ title, size, placeholder, type, name, animation = true }) => {
  const { control } = useFormContext();

  const { field, fieldState } = useController({ control, name, defaultValue: "" });

  useEffect(() => {
    if (animation) {
      Aos.init({
        duration: 300,
        offset: 200,
        once: false,
      });
    }
  }, []);

  const commonProps = {
    name,
    placeholder,
    onChange: field.onChange,
    onBlur: field.onBlur,
    ref: field.ref,
    value: field.value,
  };

  return (
    <InputWrapper
      $valid={!fieldState.error}
      size={size ? size : "100%"}
    >
      <div
        data-aos={`${animation ? "fade-right" : ""}`}
        data-aos-anchor-placement="center"
        data-aos-duration="500"
      >
        <LabelBlock>{title}</LabelBlock>

        {type === "area" ? (
          <Area {...commonProps} />
        ) : (
          <Input
            {...commonProps}
            type={type || "text"}
            {...commonProps}
          />
        )}
      </div>

      <ErrorMessageBlock>{fieldState.error?.message}</ErrorMessageBlock>
    </InputWrapper>
  );
};

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: ${(props) => props.size};
  margin-bottom: 40px;
  input,
  textarea {
    border-color: ${(props) => (props.$valid ? "#1858fb" : "#cc5e6c")};
    &:focus-visible {
      outline-color: black;
    }
  }
  p {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    color: black;
    font-size: 24px;
    @media ${breakpoints.mobile} {
      font-size: 15px;
    }
  }
  @media ${breakpoints.laptop} {
    width: 100%;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 20px;
  }
`;
const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #1858fb;
  background: #fcfcfd;
  padding: 11px 12px;

  width: 100%;
  height: 45px;

  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 18px;
  @media ${breakpoints.mobile} {
    font-size: 14px;
    padding: 8px 12px;
    height: auto;
  }
`;
const Area = styled.textarea`
  resize: none;
  border-radius: 8px;
  border: 1px solid #cfd0d7;
  background: #fcfcfd;
  padding: 10px;

  width: 100%;
  height: 45px;

  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: black;
  //opacity: 0.5;
  font-size: 18px;
  padding: 11px 12px;
  width: 988px;
  height: 253px;
  @media ${breakpoints.laptop} {
    width: 100%;
  }
  @media ${breakpoints.mobile} {
    height: 100px;
    font-size: 14px;
  }
`;

const LabelBlock = styled.p`
  align-self: stretch;
  color: #181818;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
  @media ${breakpoints.mobile} {
    margin-bottom: 8px;
  }
`;

const ErrorMessageBlock = styled.div`
  position: absolute;
  bottom: -22px;
  left: 0;
  color: #cc5e6c;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default InputLabel;
