import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import MultipurposeQuestions from "./MultipurposeQuestions";
import { nodemailerUrlBrief } from "../../../shared/constants/urls";
import axios from "axios";
import { breakpoints } from "styles/variables";
import { useMediaQuery } from "@mui/material";
import Chips from "../shared/Chips";
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { baseSchema, webDevSchema } from "./Questions.schema";
import useFormPersist from "react-hook-form-persist";
import usePersistantState from "../shared/Brief.hooks";
import { ArrowUp } from "shared/components/ArrowUp/ArrowUp";
import { useNavigate } from "react-router";

import Aos from "aos";
import "aos/dist/aos.css";
import BaseQuestions from "./BaseQuestions";
import WebDevQuestions from "./WebDevQuestions";
import SvgSelector from "shared/components/SvgSelector";

const Questions = () => {
  const [briefSent, setBriefSent] = useState(false);
  const [selectedServices, setSelectedServices] = usePersistantState("briefServices", []);
  const navigate = useNavigate();

  const validationSchema = useMemo(() => {
    let schema = baseSchema;
    if (selectedServices.includes("Web-разработка")) {
      schema = schema.merge(webDevSchema);
    }
    return schema;
  }, [selectedServices]);

  const methods = useForm({
    resolver: zodResolver(validationSchema),
    mode: "onChange",
  });

  const { handleSubmit, watch, setValue } = methods;

  useFormPersist("briefForm", {
    watch,
    setValue: setValue,
    storage: window.localStorage,
    exclude: ["technicalSpecifications"],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    Aos.init({
      duration: 300,
      offset: 200,
      once: false,
    });
  }, []);

  const sendEmail = async (data) => {
    const newData = { briefType: selectedServices, ...data };
    console.log(newData, "newData");
    const response = await axios.post(nodemailerUrlBrief, newData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response: ", response);
    if (response.status === 200) {
      setBriefSent(true);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  };

  const isTablet = useMediaQuery("(max-width: 1000px)");

  return (
    <FormProvider {...methods}>
      {briefSent ? (
        <SentScreen
          data-aos="fade-left"
          data-aos-anchor-placement="center"
          data-aos-duration="500"
        >
          <p>Ваш бриф отправлен</p>
          <p>Скоро мы с вами свяжемся</p>
        </SentScreen>
      ) : (
        <>
          {" "}
          <Wrapper>
            <ContentWr
              data-aos="fade-left"
              data-aos-anchor-placement="center"
              data-aos-duration="500"
            >
              <Title id="brief-title">Бриф</Title>
              {isTablet ? (
                <Description>
                  После получения заявки мы собираем кросс-функциональную команду для комплексной проработки предложения. Мы&nbsp;просим вас
                  отвечать на&nbsp;вопросы развёрнуто, чтобы мы смогли лучше понять ситуацию и&nbsp;разработать более релевантное
                  предложение.
                </Description>
              ) : (
                <Description>
                  После получения заявки мы собираем кросс-функциональную команду <br /> для комплексной проработки предложения.
                  Мы&nbsp;просим вас отвечать на&nbsp;вопросы <br /> развёрнуто, чтобы мы смогли лучше понять ситуацию и&nbsp;разработать
                  более
                  <br /> релевантное предложение.
                </Description>
              )}

              <Note>* помечены обязательные вопросы</Note>
            </ContentWr>

            <form
              id="brief-form"
              onSubmit={handleSubmit(sendEmail)}
            >
              <ContentWr>
                <BaseQuestions />
              </ContentWr>

              <Chips
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
              <ContentWr>
                <MultipurposeQuestions />
                {selectedServices.find((el) => el === "Web-разработка") && <WebDevQuestions />}
              </ContentWr>
            </form>
            <ArrowUp />
          </Wrapper>
          <ButtontsWr>
            <Link to={"/"}>
              <ButtonBlue type="button">
                <h5>
                  <SvgSelector svg="backArrow" />
                </h5>
              </ButtonBlue>
            </Link>

            <ButtonBlue
              form="brief-form"
              type="submit"
            >
              <h5>отправить</h5>
            </ButtonBlue>
          </ButtontsWr>
        </>
      )}
    </FormProvider>
  );
};

const Title = styled.h1`
  margin-top: 50px;
  margin-bottom: 46px;

  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #181818;
  font-size: 68px;

  @media ${breakpoints.mobile} {
    font-size: 38px;
  }
`;

const ButtonBlue = styled.button`
  background-color: #1858fb;
  border-radius: 100px;
  color: white;
  min-width: 260px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    font-family: "IBM Plex Mono", sans-serif;
    line-height: 52px;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 0 100px;
    color: white;
    @media ${breakpoints.mobile} {
      font-size: 15px;
      margin: 0 4vw;
    }
  }

  h6 {
    font-family: "Inter", sans-serif;
    font-size: 35px;
    margin: 0 100px;
    @media ${breakpoints.mobile} {
      margin: 0 4vw;
      font-size: 15px;
    }
  }

  &:disabled {
    background-color: #9b9fb1;
    pointer-events: none;
  }

  &:focus {
    opacity: 1;
  }

  &:active {
    opacity: 0.8;
  }

  @media ${breakpoints.mobile} {
    min-width: 180px;
    height: 40px;
    font-size: 15px;
    svg {
      width: 20px;
      height: 30px;
    }
  }
`;
const ButtontsWr = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  max-width: 1350px;
  padding: 0 15px;
  width: 100%;
  margin-bottom: 60px;
  gap: 10px;
  @media ${breakpoints.mobile} {
    max-width: 448px;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  max-width: 1131px;
  padding: 0 16px;
  height: 100%;
  margin: auto;

  @media ${breakpoints.mobile} {
    max-width: 512px;
  }
`;

const ContentWr = styled.div`
  max-width: 988px;
`;

const Description = styled.p`
  align-self: stretch;
  color: #181818;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 19px;
  @media ${breakpoints.mobile} {
    font-size: 16px;
  }
`;

const Note = styled.div`
  align-self: stretch;
  color: #9d9d9d;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 46px;
  @media ${breakpoints.mobile} {
    font-size: 16px;
  }
`;

const SentScreen = styled.div`
  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #181818;
  font-size: 68px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;

  gap: 20px;

  p:last-child {
    font-size: 34px;
  }
`;

export default Questions;
